<template>
  <div class="main index" v-if="!isLoading">
    <!-- For title -->
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Bank Soal</b></h1>
      </div>
      <div class="column is-12">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Bank Soal</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Bank Soal Setup</h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- For table -->
    <div class="columns is-mobile">
      <div class="column is-12">
        <div class="card card-table">
          <div class="columns is-mobile is-multiline">
            <div class="column is-10"></div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button
                  type="is-hcc"
                  title="Create Category"
                  @click="openFormBankSoal"
                >
                  Create
                </b-button>
              </b-field>
            </div>
          </div>
          <BankSoalTableActive
            :isRefresh="isRefresh"
            @clickDetailBankSoal="openDetailBankSoal"
            @clickEditFormBankSoal="openEditFormBankSoal"
          />
        </div>
      </div>
    </div>

    <FormBankSoal
      :is-open="isFormBankSoalOpen"
      @toggle-sidebar="openFormBankSoal"
      @close="closeFormBankSoal"
    />

    <FormBankSoal
      :is-open="isEditFormBankSoalOpen"
      @toggle-sidebar="openEditFormBankSoal"
      @close="closeEditFormBankSoal"
      :questionId="questionId"
      v-if="isEditFormBankSoalOpen"
    />

    <DetailBankSoal
      :is-open="isDetailBankSoalOpen"
      @toggle-sidebar="openDetailBankSoal"
      @close="closeDetailBankSoal"
      :questionId="questionId"
      v-if="isDetailBankSoalOpen"
    />
  </div>
</template>

<script>
import BankSoalTableActive from '@/components/V2/BankSoal/BankSoalTableActive'
import FormBankSoal from '@/components/V2/BankSoal/FormBankSoal'
import DetailBankSoal from '@/components/V2/BankSoal/DetailBankSoal'

export default {
  name: 'index-bank-soal',
  components: {
    BankSoalTableActive,
    FormBankSoal,
    DetailBankSoal,
  },
  data() {
    return {
      activeTab: 0,
      isLoading: false,
      isFormBankSoalOpen: false,
      isFormBankSoalEditOpen: false,
      isDetailBankSoalOpen: false,
      isEditFormBankSoalOpen: false,
      questionId: null,
      isRefresh: false,
    }
  },
  methods: {
    openFormBankSoal() {
      this.isRefresh = false
      this.isFormBankSoalOpen = true
    },
    closeFormBankSoal() {
      this.isRefresh = true
      this.isFormBankSoalOpen = false
    },
    openEditFormBankSoal(isOpen, id) {
      this.isRefresh = false
      this.isEditFormBankSoalOpen = isOpen
      this.questionId = id.toString()
    },
    closeEditFormBankSoal() {
      this.isRefresh = true
      this.isEditFormBankSoalOpen = false
      this.questionId = null
    },
    openDetailBankSoal(isOpen, id) {
      this.isRefresh = false
      this.isDetailBankSoalOpen = isOpen
      this.questionId = id.toString()
    },
    closeDetailBankSoal(isOpen, id) {
      if (id) {
        this.isDetailBankSoalOpen = isOpen
        this.questionId = null
        this.openEditFormBankSoal(true, id)
      } else {
        this.isDetailBankSoalOpen = isOpen
        this.questionId = null
      }
    },
  },
}
</script>
<style lang="scss">
.card {
  padding: 25px;
}
</style>
