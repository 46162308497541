<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <span class="column is-3">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search Question..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-2">
        <b-field>
          <b-select
            placeholder="Category"
            v-model="category"
            @input="onCategoryChange"
          >
            <option :value="null"></option>
            <option v-for="cat in categories" :value="cat.id" :key="cat.id">
              {{ cat.name }}
            </option>
          </b-select>
        </b-field>
      </span>
      <span class="column is-2">
        <b-field>
          <b-select
            placeholder="Question Type"
            v-model="questionType"
            @input="onQuestionTypeChange"
          >
            <option :value="null"></option>
            <option value="essay">Essay</option>
            <option value="radio button">Radio Button</option>
            <option value="checkbox">Checkbox</option>
            <option value="linear scale">Linear Scale</option>
            <option value="ranking">Ranking</option>
            <option value="star rating">Star Rating</option>
            <option value="matrix">Matrix</option>
          </b-select>
        </b-field>
      </span>
      <span class="column is-2">
        <b-field>
          <b-select placeholder="Level" v-model="level" @input="onLevelChange">
            <option :value="null"></option>
            <option v-for="lev in levels" :value="lev.id" :key="lev.id">
              {{ lev.name }}
            </option>
          </b-select>
        </b-field>
      </span>
      <span class="column is-2">
        <b-field>
          <b-select
            placeholder="Status"
            v-model="status"
            @input="onStatusChange"
          >
            <option :value="'Active'">Active</option>
            <option :value="'Inactive'">Inactive</option>
            <option :value="'All'">All</option>
          </b-select>
        </b-field>
      </span>
      <span class="column is-1">
        <b-field grouped position="is-right">
          <b-select
            v-model="perPage"
            @input="onPerPageChange"
            style="width:70px!important;"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div>

    <b-table
      :data="
        questionStorages && questionStorages.data ? questionStorages.data : []
      "
      :current-page.sync="currentPage"
      paginated
      backend-pagination
      :total="
        questionStorages && questionStorages.total ? questionStorages.total : 0
      "
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      :loading="isLoadingTable"
    >
      <!-- For id -->
      <b-table-column field="id" label="Questions Id" sortable v-slot="props">
        <span @click="openFormBankSoal(props.row.id)" class="question-id">
          {{ props.row.id }}
        </span>
      </b-table-column>

      <!-- For name -->
      <b-table-column field="name" label="Questions" sortable v-slot="props">
        <span @click="openFormBankSoal(props.row.id)" class="question-name">
          {{ props.row.name }}
        </span>
      </b-table-column>

      <!-- For category -->
      <b-table-column
        field="category_name"
        label="Category"
        v-slot="props"
        sortable
      >
        {{ props.row.categoryName }}
      </b-table-column>

      <!-- For question type -->
      <b-table-column
        field="question_type"
        label="Question Type"
        sortable
        v-slot="props"
      >
        {{ props.row.questionType }}
      </b-table-column>

      <!-- For question level -->
      <b-table-column field="level_rank" label="Level" v-slot="props" sortable>
        {{ props.row.levelName }}
      </b-table-column>

      <!-- For used -->
      <b-table-column field="counter" label="Used" sortable v-slot="props">
        {{ props.row.counter }}
      </b-table-column>

      <!-- For status -->
      <b-table-column field="status" label="Status" v-slot="props">
        <span
          :class="
            props.row.deletedAt === null ? 'tag is-success' : 'tag is-danger'
          "
        >
          {{ props.row.deletedAt === null ? 'active' : 'inactive' }}
        </span>
      </b-table-column>
      <b-table-column field="action" centered label="Action" v-slot="props">
        <b-button
          @click="openDetailBankSoal(props.row.id)"
          type="icon-button"
          icon-left="eye"
        ></b-button>
        <b-button
          type="icon-button"
          icon-left="copy"
          title="Duplicate Question"
          @click="duplicatePopup(props.row.id)"
        ></b-button>
        <b-button
          v-if="props.row.deletedAt === null"
          type="icon-button"
          icon-left="archive"
          title="Inactive Question"
          @click="inactivePopup(props.row.id)"
        ></b-button>
        <b-button
          v-if="props.row.deletedAt !== null"
          type="icon-button"
          icon-left="undo-alt"
          title="Active Bank Soal"
          @click="restorePopup(props.row.id)"
        ></b-button>
        <b-button
          v-if="props.row.deletedAt !== null"
          type="icon-button"
          icon-left="trash"
          title="Delete Question"
          @click="deletePopup(props.row.id)"
        ></b-button>
      </b-table-column>
      <template slot="bottom-left">
        {{
          questionStorages && questionStorages.from ? questionStorages.from : 0
        }}
        -
        {{ questionStorages && questionStorages.to ? questionStorages.to : 0 }}
        from
        {{
          questionStorages && questionStorages.total
            ? questionStorages.total
            : 0
        }}
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>Data not found.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  components: {},
  props: {
    isRefresh: Boolean,
  },
  data() {
    const today = new Date()
    return {
      currentDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      isLoadingTable: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'created_at',
      sortOrder: 'DESC',
      page: 1,
      search: null,
      category: null,
      questionType: null,
      level: null,
      status: 'Active',
      categories: [],
      levels: [],
      link: '',
      message: {
        text: '',
        type: '',
      },
      isModalPreviewActive: false,
      isModalLinkActive: false,
      isDetailBankSoalOpen: false,
      questionId: '',
    }
  },
  async mounted() {
    await this.fetchData(
      this.perPage,
      this.currentPage,
      this.sortField,
      this.sortOrder,
      this.search,
      this.status,
      this.category,
      this.questionType,
      this.level
    )
  },
  created() {
    this.fetchLevels()
    this.getCategories()
  },
  methods: {
    ...mapActions({
      fetchQuestionStorages: 'questionStorage/fetchQuestionStorages',
      inactiveQuestionStorageData: 'questionStorage/inactiveQuestionStorage',
      restoreQuestionStorageData: 'questionStorage/restoreQuestionStorage',
      deleteQuestionStorageData: 'questionStorage/deleteQuestionStorage',
      duplicateQuestionStorageData: 'questionStorage/duplicateQuestionStorage',
    }),

    // For search question storage
    searchQuestionStorage: debounce(function(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status,
          this.category,
          this.questionType,
          this.level
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status,
          this.category,
          this.questionType,
          this.level
        )
      }
    }, 500),

    // For duplicate popup
    duplicatePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Duplicate Assessment',
        message: `Are you sure want to duplicate this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, duplicate it!',
        type: 'is-hcc',
        onConfirm: () => this.duplicateQuestion(id),
      })
    },

    // For duplicate question
    async duplicateQuestion(id) {
      this.isLoadingTable = true
      try {
        await this.duplicateQuestionStorageData(id)

        this.success('Duplicate Question Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },
    // For restore popup
    restorePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Restore Bank Soal',
        message: `Are you sure want to restore this bank soal?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, restore it!',
        type: 'is-hcc',
        onConfirm: () => this.restoreQuestionStorage(id),
      })
    },

    // For restore bank soal
    async restoreQuestionStorage(id) {
      try {
        await this.restoreQuestionStorageData(id)

        this.success('Restore Bank Soal Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For inactive popup
    inactivePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Inactive Question Storage',
        message: `Are you sure want to inactive this question storage?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, inactive it!',
        type: 'is-hcc',
        onConfirm: () => this.inactiveQuestionStorage(id),
      })
    },

    // For inactive question storage
    async inactiveQuestionStorage(id) {
      try {
        await this.inactiveQuestionStorageData(id)

        this.success('Inactive Question Storage Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For delete popup
    deletePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete Bank Soal',
        message: `Are you sure want to delete this bank soal? `,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteQuestionStorage(id),
      })
    },

    // For delete bank soal
    async deleteQuestionStorage(id) {
      try {
        await this.deleteQuestionStorageData(id)
        if (this.deleteQuestionResponse.status == 500) {
          this.danger(this.deleteQuestionResponse.message)
        } else {
          this.success('Delete Question Success')
        }
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For fetching data
    async fetchData(
      perPage,
      page,
      sortField,
      sortOrder,
      search,
      status,
      category,
      questionType,
      level
    ) {
      let data = {
        perPage: perPage,
        page: page,
        sortField: sortField,
        sortOrder: sortOrder,
        search: search,
        status: status,
        category: category,
        questionType: questionType,
        level: level,
      }
      this.isLoadingTable = true
      try {
        await this.fetchQuestionStorages(data)
      } catch (err) {
        this.danger(err)
      }
      this.isLoadingTable = false
      // this.isRefresh = false
    },

    // For Page Change
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For Sorting Data
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For per page change
    onPerPageChange(value) {
      this.perPage = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },
    // For category change
    onCategoryChange(value) {
      this.category = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For questionType change
    onQuestionTypeChange(value) {
      this.questionType = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For level change
    onLevelChange(value) {
      this.level = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    // For status change
    onStatusChange(value) {
      this.status = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status,
        this.category,
        this.questionType,
        this.level
      )
    },

    getCategories() {
      this.$store
        .dispatch('newCategory/getAllCategories')
        .then(data => (this.categories = data.data))
    },

    fetchLevels() {
      this.$store
        .dispatch('newLevel/getLevels')
        .then(data => (this.levels = data))
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },

    openDetailBankSoal(id) {
      this.isDetailBankSoalOpen = true
      this.$emit('clickDetailBankSoal', this.isDetailBankSoalOpen, id)
    },

    openFormBankSoal(id) {
      this.isFormBankSoalOpen = true
      this.$emit('clickEditFormBankSoal', this.isFormBankSoalOpen, id)
    },
  },
  watch: {
    search: function(val) {
      this.searchQuestionStorage(val)
    },
    isRefresh: function(val) {
      if (val == true) {
        this.fetchData(
          this.perPage,
          this.currentPage,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status,
          this.category,
          this.questionType,
          this.level
        )
      }
    },
  },
  computed: {
    ...mapGetters({
      questionStorages: 'questionStorage/getQuestionStorages',
      deleteQuestionResponse: 'questionStorage/getDeleteQuestionResponse',
    }),
  },
}
</script>
<style scoped>
.modal-copy-link {
  width: 500px !important;
}
.question-name:hover {
  cursor: pointer;
}
</style>
